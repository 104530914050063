
/* Special version button */
.version-button {
  /* padding: 4px 12px 4px 12px !important; */
  /* border: 1px solid #337AB7; */
  /* border-radius: 5px; */
  /* margin: 5px -55px 0 0; */
}

.version-button>i:before {
  font-size: xx-small;
}
