

/* Image floats */

img.float-right {
  float: right;
  margin-left: 20px;
  margin-bottom: 5px;
}

img.right-thumbnail {
  float: right;
  margin-left: 20px;
  margin-bottom: 5px;
  width: 25%;
}

img.float-left {
  float: left;
  margin-right: 20px;
  margin-bottom: 5px;
}



img.full-width {
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;

  /* add shadow to all full-size images since they often have white around them */
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 10px 0;
}


/* 
  add shadow to all full-size images since they often have white around them
  note that you should prefer full-width or something, as that also ensures spacing around it
*/
img.glow {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 10px 0;
}